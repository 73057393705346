import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Program() {
  
    return (
      <>
      <section id="programs" className='py-5'>
        <Container>
        <h2 className="text-center zag">Choose program</h2>
            <Row className='list'>
       

       <Col md="4" className='mb-3'>
       <div className="price">
            <div className="d-flex align-items-center justify-content-center position-absolute top-0 start-50 translate-middle bg-primary rounded-circle">
                <i className="fa fa-share-alt text-white"></i>
            </div>
            <div className="text-center border-bottom p-4 pt-5">
                <h4 className="fw-bold">SILVER</h4>
                
            </div>
            <div className="text-center border-bottom p-4">
                <p className="mb-1">Freezing period</p>
                <h2 className="mb-3">
                    3 months
                </h2>
                
            </div>
            <div className="p-4">
                <p className="border-bottom pb-3">Rate <i className="me-3">from 0.15% per day</i></p>
                <p className="border-bottom pb-3">Access to Airdrop<i className="check me-3">&#10005;</i></p>
                <p className="border-bottom pb-3">Access to Lootbox<i className="check me-3">&#10005;</i></p>
                <p className="border-bottom pb-3">Reinvest<i className="check me-3">&#10003;</i></p>
                <p className="border-bottom pb-3">Free transactions <i className="me-3">10</i></p>
                <p className="mb-0">Penalty for unfreezing before expiration <i className="me-3">40%</i></p>
            </div>
        </div>
        </Col>

        <Col md="4" className='mb-3'>
       <div className="price">
            <div className="d-flex align-items-center justify-content-center position-absolute top-0 start-50 translate-middle bg-primary rounded-circle">
                <i className="fa fa-share-alt text-white"></i>
            </div>
            <div className="text-center border-bottom p-4 pt-5">
                <h4 className="fw-bold">GOLD</h4>
                
            </div>
            <div className="text-center border-bottom p-4">
                <p className="mb-1">Freezing period</p>
                <h2 className="mb-3">
                    6 months
                </h2>
                
            </div>
            <div className="p-4">
                <p className="border-bottom pb-3">Rate <i className="me-3">from 0.24% per day</i></p>
                <p className="border-bottom pb-3">Access to Airdrop<i className="check me-3">&#10003;</i></p>
                <p className="border-bottom pb-3">Access to Lootbox<i className="check me-3">&#10003;</i></p>
                <p className="border-bottom pb-3">Reinvest<i className="check me-3">&#10003;</i></p>
                <p className="border-bottom pb-3">Free transactions <i className="me-3">25</i></p>
                <p className="mb-0">Penalty for unfreezing before expiration <i className="me-3">30%</i></p>
            </div>
        </div>
        </Col>

        <Col md="4" className='mb-3'>
       <div className="price">
            <div className="d-flex align-items-center justify-content-center position-absolute top-0 start-50 translate-middle bg-primary rounded-circle">
                <i className="fa fa-share-alt text-white"></i>
            </div>
            <div className="text-center border-bottom p-4 pt-5">
                <h4 className="fw-bold">PLATINUM</h4>
                
            </div>
            <div className="text-center border-bottom p-4">
                <p className="mb-1">Freezing period</p>
                <h2 className="mb-3">
                    1 year
                </h2>
                
            </div>
            <div className="p-4">
                <p className="border-bottom pb-3">Rate <i className="me-3">from 0.3% per day</i></p>
                <p className="border-bottom pb-3">Access to Airdrop<i className="check me-3">&#10003;</i></p>
                <p className="border-bottom pb-3">Access to Lootbox<i className="check me-3">&#10003;</i></p>
                <p className="border-bottom pb-3">Reinvest<i className="check me-3">&#10003;</i></p>
                <p className="border-bottom pb-3">Free transactions <i className="me-3">50</i></p>
                <p className="mb-0">Penalty for unfreezing before expiration <i className="me-3">25%</i></p>
            </div>
        </div>
        </Col>


</Row>
</Container>
</section>
      </>
    );
  }
  
  export default Program;