import './App.css';
import NavbarNologin from './components/navbar';
import Footer from './components/footer';
import FAQ from './components/faq';
import Referral from './components/referral';
import RoadMap from './components/roadmap';
import Program from './components/program';
import Stake from './components/stake';
import Carusel from './components/carusel';
import First from './components/first';
import MainBut from './components/main_but';

function App() {
  return (
    <>
      <div className="App">
        <header className="App-header">
         
        </header>
      <div className='bg_header'>

            <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
    
        <NavbarNologin />
        <First />
        <MainBut />
      </div>
     
        <Carusel />
        <Stake />
        <Program />
        <RoadMap />
        <Referral />
        <FAQ />
        <Footer />
      </div>
    </>
  );
}

export default App;
