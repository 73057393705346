import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Referral() {
  
    return (
      <>
       <section id="referral" className='py-5'>
      <Container>
      <Row className="justify-content-center">
        <h2 className="text-center text-green mb-5">AIST Referral program</h2>
        <p className="text-white">You receive a 7% referral bonus from all staked tokens of the user who registered awallet on the AIST platform and activated staking from all transactions, as well as 3% from his referrals in the form of cashback. These funds are not deducted from thestaking of your referrals, but are paid out of a separate marketing wallet by the system each time your referrals and sub-referrals make token picks.</p>
        <p className="text-white">You also receive 1% if you put a referral address when activate staking program or have any referrals.</p>
       <Image src="referal.svg"  className='py-5' />
       <h3 className='text-green'>IMPORTANT!</h3>
       <p className="text-white">1. Referral bonus is not deducted from the referral’s income, but comes on top of «cashback» from a separate referral wallet.</p>
       <p className="text-white">2. There are only two levels in the system, but there is no limit on the number. If you have invited 1000 people, each of them will receive a bonus from their Claim (7%), and from all their friends of the 1st circle more dop bonus (3%).</p>
       </Row>
       </Container>
       </section>
       
      </>
    );
  }
  
  export default Referral;