import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function RoadMap() {
  
    return (
      <>
      <section id="roadmap" className='text-green py-5'>
      <Container>
      <Row className="justify-content-center">
        <h2 className="text-center mb-3">Roadmap</h2>
       <Image src="road_map.svg"  className='py-5'/>
       </Row>
       </Container>
       </section>
      </>
    );
  }
  
  export default RoadMap;