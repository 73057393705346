import Button from 'react-bootstrap/Button';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function MainBut() {

    const [showA, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);
  
    const toggleShowA = () => setShowA(!showA);
    const toggleShowB = () => setShowB(!showB);
  
  return (
    <>
      
<section id="mainbut">
     <Container className='butt mt-5 pb-5'>
    
     <Toast onClose={toggleShowB} show={showB} animation={true}>
       
       <Toast.Body>
         <Row className="justify-content-center">
            <Col className="bt" md="3"><div>Outsource</div></Col>
            <Col className="bt" md="3"><div>Investment fund</div></Col>
            <Col className="bt" md="3"><div>Augmented reality</div></Col>
            <Col className="bt" md="3"><div>Games & Ent.</div></Col>
            <Col className="bt" md="3"><div>AIST GPT</div></Col>
            <Col className="bt" md="3"><div>AIST_motion</div></Col>
            <Col className="bt" md="3"><div>Trading_bot</div></Col>
         </Row>
         </Toast.Body>
     </Toast>

     <Row className="justify-content-center">
     <button onClick={toggleShowB} className="mb-2 bt1">
AI_Universe
Platform
        </button>

        <div className="chevroncont">
		<div className="chevronup"></div>
  <div className="chevronup"></div>
  <div className="chevronup"></div>
  <div className="chevrondown"></div>
  <div className="chevrondown"></div>
  <div className="chevrondown"></div>
</div>
        </Row>

        <Row className="justify-content-center">
        <button onClick={toggleShowA} className="mb-2 bt2">
AIST Foundation
</button>
</Row>
<Toast onClose={toggleShowA} show={showA} animation={true}>
       
       <Toast.Body>
         <Row className="justify-content-center">
            <Col className="bt0" md="3"><div>xAIST staking</div></Col>
            <Col className="bt0" md="3"><div>AIST Staking</div></Col>
            <Col className="bt0" md="3"><div>Partnerships & team expand</div></Col>
         </Row>
         </Toast.Body>
     </Toast>
</Container>
</section>
    </>
  );
}

export default MainBut;