
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function Footer() {
    const handleAnchorClick = (event, message) => {
 
    };
    return (
      <>
      <section  id="footer-nav">
         <Navbar collapseOnSelect expand="md" className="">
            <Container>
                <Navbar.Brand href="/"><Image src="../../AIST_white_logo.svg" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav className="me-auto justify-content-center flex-grow-1 pe-3">

                      <Nav.Link href="/">Home</Nav.Link>
                      <Nav.Link href="https://aist-ai.gitbook.io/aist-ai">Docs</Nav.Link>
                      <Nav.Link href="https://t.me/aists_ai">Blog</Nav.Link>
                      <Nav.Link href="https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x5033B5b12980CE1b270bf3e7376B47F6Ad502eBE">DEX</Nav.Link>
                      <Nav.Link href="https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x5033B5b12980CE1b270bf3e7376B47F6Ad502eBE">Pancake</Nav.Link>
                    
                    </Nav>

                    <Nav className="flex-column">
                    
                      <Nav.Link eventKey={2} href="mailto:support@aists.ai" className='text-green'>
                        support@aists.ai
                      </Nav.Link>

                      <Nav.Link eventKey={2} href="mailto:marketing@aists.ai" className='text-green'>
                        marketing@aists.ai
                      </Nav.Link>

                    </Nav>
                </Navbar.Collapse>
                
            </Container>
        </Navbar>
        <Container>
            <Row className='soc justify-content-lg-end justify-content-center mt-3'>
                <Button variant="link" onClick={event =>
          handleAnchorClick(event, 'https://twitter.com/AistToken')
        }
        href="https://twitter.com/AistToken"
        target="_blank"
        rel="noreferrer"><i>𝕏</i> Twitter</Button>
                <Button variant="link" onClick={event =>
          handleAnchorClick(event, 'https://t.me/aists_ai_chat/')
        }
        href="https://t.me/aists_ai_chat/"
        target="_blank"
        rel="noreferrer"><i><svg width="15px" height="15px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#181818">

<g id="SVGRepo_bgCarrier" />

<g id="SVGRepo_tracerCarrier" />

<g id="SVGRepo_iconCarrier"> <path d="M41.4193 7.30899C41.4193 7.30899 45.3046 5.79399 44.9808 9.47328C44.8729 10.9883 43.9016 16.2908 43.1461 22.0262L40.5559 39.0159C40.5559 39.0159 40.3401 41.5048 38.3974 41.9377C36.4547 42.3705 33.5408 40.4227 33.0011 39.9898C32.5694 39.6652 24.9068 34.7955 22.2086 32.4148C21.4531 31.7655 20.5897 30.4669 22.3165 28.9519L33.6487 18.1305C34.9438 16.8319 36.2389 13.8019 30.8426 17.4812L15.7331 27.7616C15.7331 27.7616 14.0063 28.8437 10.7686 27.8698L3.75342 25.7055C3.75342 25.7055 1.16321 24.0823 5.58815 22.459C16.3807 17.3729 29.6555 12.1786 41.4193 7.30899Z" fill="#181818"/> </g>

</svg></i> Chat</Button>
                <Button variant="link" onClick={event =>
          handleAnchorClick(event, 'https://t.me/aists_ai')
        }
        href="https://t.me/aists_ai"
        target="_blank"
        rel="noreferrer"><i><svg width="15px" height="15px" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#181818">

<g id="SVGRepo_bgCarrier"/>

<g id="SVGRepo_tracerCarrier"/>

<g id="SVGRepo_iconCarrier"> <path d="M41.4193 7.30899C41.4193 7.30899 45.3046 5.79399 44.9808 9.47328C44.8729 10.9883 43.9016 16.2908 43.1461 22.0262L40.5559 39.0159C40.5559 39.0159 40.3401 41.5048 38.3974 41.9377C36.4547 42.3705 33.5408 40.4227 33.0011 39.9898C32.5694 39.6652 24.9068 34.7955 22.2086 32.4148C21.4531 31.7655 20.5897 30.4669 22.3165 28.9519L33.6487 18.1305C34.9438 16.8319 36.2389 13.8019 30.8426 17.4812L15.7331 27.7616C15.7331 27.7616 14.0063 28.8437 10.7686 27.8698L3.75342 25.7055C3.75342 25.7055 1.16321 24.0823 5.58815 22.459C16.3807 17.3729 29.6555 12.1786 41.4193 7.30899Z" fill="#181818"/> </g>

</svg></i> Channel</Button>
            </Row>
        <Row className='mt-5'>
                    <Col md="6" className='text-white'><p>AIST 2024 © All rights reserved</p></Col>
                    <Col md="6" className='text-white text-lg-end text-start'><a href="privacy_policy_and_terms_of_service.pdf" target="_blank" rel="noreferrer">Privacy policy and Terms of Service</a></Col>
                    </Row>
            </Container>

            </section>
      </>
    );
  }
  
  export default Footer;