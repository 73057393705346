import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

function Carusel() {
    var settings = {
        autoplay: true,
        infinite: true,
        lazyLoad: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 840,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ],
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1990,
        autoplaySpeed: 2000,
        swipeToSlide: true,
        pauseOnHover: true
      };
    return (
      <>
      <section id="slider">

      <Slider {...settings}>
      <div className='slide sl1'>
        <h2>2024</h2>
        <p>Claim Proof of stake tokens AIST</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl2'>
        <h2>2022</h2>
        <p>Web3 metaverse, NFT gains</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl3'>
        <h2>2020/21</h2>
        <p>DeFi, DeX<br />Liquidity pools</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl4'>
        <h2>2019</h2>
        <p>Stable coins liquidity staking</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl5'>
        <h2>2017</h2>
        <p>Delegated Byzantine Fault Tolerance project</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl6'>
        <h2>2015</h2>
        <p>Proof-of Space-time</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl7'>
        <h2>2014</h2>
        <p>PoSe staking with masternodes</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl8'>
        <h2>2013</h2>
        <p>Proof of Stake project</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl9'>
        <h2>2012</h2>
        <p>ASIC mining PoW</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl10'>
        <h2>2010</h2>
        <p>CPU mining for bitcoin PoW</p>
        <div className='slide-bg'></div>
      </div>
      <div className='slide sl11'>
        <h2>2009</h2>
        <p>CPU mining for bitcoin PoW</p>
        <div className='slide-bg'></div>
      </div>
    </Slider>
      
</section>
      </>
    );
  }
  
  export default Carusel;