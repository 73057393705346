import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import React, { useState } from 'react';
import Toast from 'react-bootstrap/Toast';

function First() {


    const [showB, setShowB] = useState(true);
  

    const toggleShowB = () => setShowB(!showB);

    const handleAnchorClick = (event, message) => {

      };
  
  return (
    <>
        <Container className='head-txt d-flex align-items-center justify-content-center'>
       
        <Row className="col-md-9 d-flex align-items-center justify-content-center">
     <h1 className='text-center text-white mt-5'>AIST</h1>
     <h2 className='text-center text-white'>Cryptocurrency staking of the newest kind</h2>

     <Col md="4" className='d-flex align-items-center justify-content-center mt-5'>
      <Button className='btn1' variant="link" onClick={event =>
          handleAnchorClick(event, 'https://app.aists.ai/')
        }
        href="https://app.aists.ai/"
        target="_blank"
        rel="noreferrer"><Image src="/Button1.svg" /></Button>
      </Col>
      <Col md="4" className='d-flex align-items-center justify-content-center mt-4'>
      <Button className='btn1' variant="link" onClick={event =>
          handleAnchorClick(event, 'https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x5033B5b12980CE1b270bf3e7376B47F6Ad502eBE')
        }
        href="https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x5033B5b12980CE1b270bf3e7376B47F6Ad502eBE"
        target="_blank"
        rel="noreferrer"><Image src="/Button2.svg" /></Button>
      </Col>
    </Row>
   
   
     </Container>

    
    </>
  );
}

export default First;