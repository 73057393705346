import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

function Stake() {
    const handleAnchorClick = (event, message) => {
 
      };
    return (
<>
<section id="stake" className='py-5'>
    <Container>
        <Row className="justify-content-center">
            <Col xs="9">
                <Row className="txt justify-content-center">
                    <Col xs="3"><p>Name</p></Col>
                    <Col xs="9"><p>Artificial intelligence staking token</p></Col>
                    <Col xs="3"><p>Ticker</p></Col>
                    <Col xs="9"><p>AIST</p></Col>
                    <Col xs="3"><p>Type</p></Col>
                    <Col xs="9"><p>BEP-20</p></Col>
                    <Col xs="3"><p>Blockchain</p></Col>
                    <Col xs="9"><p>Binance Smart Chain</p></Col>
                    <Col xs="3"><p>Issue</p></Col>
                    <Col xs="9"><p>100 000 000</p></Col>
                    <Col xs="3"><p>Type of issue</p></Col>
                    <Col xs="9"><p>limited</p></Col>
                    <Col xs="3"><p>Smart Contract</p></Col>
                    <Col xs="9"><p>0x5033B5b12980CE1b270&shy;bf3e7376B47F6Ad502eBE</p></Col>
                </Row>
            </Col>
        </Row>
    </Container>
</section>

<section id="stake2" className='py-5'>
    <Container>
      <Row className="justify-content-center">
        <Col xs="9">
            <Row className="d-flex align-items-center justify-content-center">
                <Col xs="12" sm="12" md="12" lg="6">
                    <Row className="justify-content-center">
                        <Col xs="12 d-flex align-items-center justify-content-center"><h3 className='bt1'>Total supply</h3></Col>
                        <Col xs="12 d-flex align-items-center justify-content-center"><h5 className='uline log'>100 000 000</h5></Col>
                    </Row>
                </Col>
                <Col xs="12" sm="12" md="12" lg="6">
                    <Row className="justify-content-center txt">
                        <Col xs="12" md="12" lg="6" className='bt2'>Staking pool</Col>
                        <Col xs="12" md="12" lg="6" className='d-flex align-items-center justify-content-center justify-content-lg-start '><h5 className='uline'>75 000 000</h5></Col>

                        <Col xs="12" md="12" lg="6" className='bt2'>Marketing & Referral program</Col>
                        <Col xs="12" md="12" lg="6" className='d-flex align-items-center justify-content-center justify-content-lg-start '><h5 className='uline'>15 000 000</h5></Col>

                        <Col xs="12" md="12" lg="6" className='bt2'>Initial Allocation</Col>
                        <Col xs="12" md="12" lg="6" className='d-flex align-items-center justify-content-center justify-content-lg-start '><h5 className='uline'>5 000 000</h5></Col>

                        <Col xs="12" md="12" lg="6" className='bt2'>Team funds, freezed</Col>
                        <Col xs="12" md="12" lg="6" className='d-flex align-items-center justify-content-center justify-content-lg-start '><h5 className='uline'>5 000 000</h5></Col>
                    </Row>
                </Col>
            </Row>
        </Col>
      </Row>
    </Container>
</section>

<section id="stake3" className='py-5'>
    <Container>
        <Row className="justify-content-center">
            <Col xs="12" sm="12" md="12" lg="9">
                <Row className="txt justify-content-center">
                    <Col xs="12" sm="12" md="12" lg="4">
                        <h2 className='ps-3'>Stake AIST</h2>
                        <ul>
                            <li><span>From 100 to 10k tokens</span></li>
                            <li><span>Gain 65-125% APR, 200+% APY</span></li>
                            <li><span>Every claim and other transactions grow AIST liquidity pool</span></li>
                        </ul>
                        <Button className='bt1 ms-3'  variant="link" onClick={event =>
          handleAnchorClick(event, 'https://app.aists.ai/')
        }
        href="https://app.aists.ai/"
        target="_blank"
        rel="noreferrer">Try it out &#8594;</Button>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="8">
                        <div id="slider" className=' mt-5 mt-lg-0'>
                            <div className="allpic">
                                <h3>Grow AIST</h3>
                                <p>liquidity pool</p>
                            </div>
                            <div className="allpic">
                                <h3>200+% APY</h3>
                                <p>by holding AIST</p>
                            </div>
                            <div className="allpic">
                                <h3>Stake AIST</h3>
                                <p>From 100 to 10k tokens</p>
                            </div>                 
                        </div>
                    </Col>

                </Row>
            </Col>
        </Row>
    </Container>
</section>




      </>
    );
  }
  
  export default Stake;