import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function FAQ() {
  
    return (
      <>
      <section id="faq" className='py-5'>
      <Container>
      <Row className="justify-content-center">
        <Col md="9">
        <Accordion defaultActiveKey={['0']}>
            <Accordion.Item eventKey="0">
                <Accordion.Header>What is AIST platform and its purpose?</Accordion.Header>
                <Accordion.Body>
                <p>AIST platform is a versatile platform that allows users to stake and claim our token for various purposes, leveraging the benefits of artificial intelligence technology.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Where is the AIST token built on?</Accordion.Header>
                <Accordion.Body>
                <p>The AIST token operates on the Binance Smart Chain (BEP20) utilizing the smart contract address: 0x5033B5b12980CE1b270bf3e7376B47F6Ad502eBE.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>How can I stake AIST tokens?</Accordion.Header>
                <Accordion.Body>
                <p>To stake AIST tokens, you can connect your wallet (Metamask, WalletConnect etc) to the AIST platform and follow the staking instructions provided.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>What are the benefits of staking AIST tokens?</Accordion.Header>
                <Accordion.Body>
                <p>Staking AIST tokens can offer various benefits such as earning rewards, participating in governance decisions, and contributing to the stability of the platform.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>Is there a minimum amount required to stake AIST tokens?</Accordion.Header>
                <Accordion.Body>
                <p>The minimum staking amount for AIST tokens may vary based on the platform's guidelines.
Minimum quantity for steaking 100 AIST.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>How do I claim rewards from staking AIST tokens?</Accordion.Header>
                <Accordion.Body>
                <p>You can claim rewards from staking AIST tokens by following the instructions provided on the platform, typically through a simple claim process.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>Can I unstake my AIST tokens at any time?</Accordion.Header>
                <Accordion.Body>
                <p>Yes, in most cases, you can unstake your AIST tokens at any time, subject to any lock-up periods or restrictions specified by the platform.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>Where can I sell or buy AIST tokens?</Accordion.Header>
                <Accordion.Body>
                <p>You can sell or buy on the well-known PancakeSwap platform at https://pancakeswap.finance/swap?outputCurrency=0x5033B5b12980CE1b270bf3e7376B47F6Ad502eBE by connecting via Metamask or WalletConnect or any convenient application for working with PancakeSwap.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>Are there any risks associated with staking AIST tokens?</Accordion.Header>
                <Accordion.Body>
                <p>Staking AIST tokens, like any other investment, carries inherent risks. It's essential to understand these risks, such as market volatility, smart contract vulnerabilities, and regulatory changes.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
                <Accordion.Header>How can I track my staking rewards and activities on the AIST platform?</Accordion.Header>
                <Accordion.Body>
                <p>You can monitor your staking rewards and activities on the AIST platform through your connected wallet or by accessing the staking dashboard provided by the platform.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
                <Accordion.Header>Where can I find more information about the AIST platform and its staking opportunities?</Accordion.Header>
                <Accordion.Body>
                <p>For more detailed information about the AIST platform, staking opportunities, and the latest updates, please visit our official website or reach out to our customer support team for assistance.</p>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </Col>
        </Row>
    </Container>

    </section>

      </>
    );
  }
  
  export default FAQ;