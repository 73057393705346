import { Routes, Route, NavLink, Link } from "react-router-dom";
import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';

function NavbarNologin() {
  
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="" id="header-nav">
        <Container>
          <Navbar.Brand href="/"><Image src="../../AIST_white_logo.svg" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto justify-content-center flex-grow-1 pe-3">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="https://aist-ai.gitbook.io/aist-ai">Docs</Nav.Link>
                    <Nav.Link href="https://t.me/aists_ai">Blog</Nav.Link>
                    <Nav.Link href="https://pancakeswap.finance/swap?outputCurrency=BNB&inputCurrency=0x5033B5b12980CE1b270bf3e7376B47F6Ad502eBE">DEX</Nav.Link>
              
            </Nav>
            <Nav>
            
              <Nav.Link eventKey={2} href="https://app.aists.ai/" className="bt1">
                Connect wallet
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default NavbarNologin;